import "../Custom.css";
import {
  DIGITAL_SERVICE_PAGE_IMAGE_FOUR,
  DIGITAL_SERVICE_PAGE_IMAGE_ONE,
  DIGITAL_SERVICE_PAGE_IMAGE_THREE,
  DIGITAL_SERVICE_PAGE_IMAGE_TWO,
} from "../utils/constants";

const DigitalSolutions = () => {
  return (
    <div className="container pb-5">
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="row p-2 animated animatedFadeInUp fadeInUp animation-delay3">
            <h1
              className="fw-bold d-none d-md-block text-secondary text-black"
              style={{ fontSize: "80px" }}
            >
              Check what we do<span className="text-warning">.</span>
            </h1>
            <h1
              className="fw-bold d-block d-md-none text-secondary text-black"
              style={{ fontSize: "40px" }}
            >
              Check what we do<span className="text-warning">.</span>
            </h1>
          </div>

          <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay3">
            <img
              src={DIGITAL_SERVICE_PAGE_IMAGE_ONE}
              className="transform"
              alt="web-development"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay4">
            <p className="fw-bold text-secondary">Web Development</p>
            <p style={{ color: "#495057" }}>
              <small>
                We built responsive and user-friendly websites and web
                applications that are both attractive and professional for your
                worldwide presence.
              </small>
            </p>
          </div>

          <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay3">
            <img
              src={DIGITAL_SERVICE_PAGE_IMAGE_TWO}
              className="transform"
              alt="app-development"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay4">
            <p className="fw-bold text-secondary">App Development</p>
            <p style={{ color: "#495057" }}>
              <small>
                We offer portable and optimised IT solutions that will enable
                your business to go online using Android and iOS technologies.
              </small>
            </p>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay5">
            <img
              src={DIGITAL_SERVICE_PAGE_IMAGE_THREE}
              className="transform"
              alt="digital-marketing"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay6">
            <p className="fw-bold text-secondary">Digital Marketing</p>
            <p style={{ color: "#495057" }}>
              <small>
                For any kind of digital transformation understanding the
                business and your product to influence your end user With Social
                Media Marketing, SEO, Google Analytics, Adwords & Affiliate
                Marketing.
              </small>
            </p>
          </div>

          <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay5">
            <img
              src={DIGITAL_SERVICE_PAGE_IMAGE_FOUR}
              className="transform"
              alt="graphic-designing"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay6">
            <p className="fw-bold text-secondary">Graphic Designing</p>
            <p style={{ color: "#495057" }}>
              <small>
                This is the best tool used to attract your target audience. We
                create visual treats important for your online business.
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalSolutions;
