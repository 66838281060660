import "../Custom.css";
import logo from "../logo.jpg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-black">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-md pb-4 pb-md-0 text-center text-md-start">
            <Link to="/">
              <img src={logo} alt="logo" width={"auto"} height={"65px"} />
            </Link>
            <small className="d-block mb-3 text-muted">
              &copy; {new Date().getFullYear() + " All Rights Reserved."}
            </small>
          </div>
          <div className="col-6 col-md text-center text-md-start">
            <h6 className="text-light pb-3">Services</h6>
            <ul className="list-unstyled text-small">
              <li>
                <Link
                  to="/international-commerce"
                  style={{ textDecoration: "none" }}
                >
                  <span className="link-secondary text-decoration-none animated animatedFadeInUp fadeInUp animation-delay2">
                    International Commerce
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/accounting-and-bookkeeping"
                  style={{ textDecoration: "none" }}
                >
                  <span className="link-secondary text-decoration-none animated animatedFadeInUp fadeInUp animation-delay4">
                    Accounting & Bookkeeping
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/overseas-business-consultants"
                  style={{ textDecoration: "none" }}
                >
                  <span className="link-secondary text-decoration-none animated animatedFadeInUp fadeInUp animation-delay4">
                    Overseas Business Consultants
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/digital-solutions"
                  style={{ textDecoration: "none" }}
                >
                  <span className="link-secondary text-decoration-none animated animatedFadeInUp fadeInUp animation-delay5">
                    Digital Solutions
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md text-center text-md-start">
            <h6 className="text-light pb-3">About</h6>
            <ul className="list-unstyled text-small">
              <li>
                <Link to="/about" style={{ textDecoration: "none" }}>
                  <span className="link-secondary text-decoration-none animated animatedFadeInUp fadeInUp animation-delay2">
                    Who we are
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/contact" style={{ textDecoration: "none" }}>
                  <span className="link-secondary text-decoration-none animated animatedFadeInUp fadeInUp animation-delay3">
                    Contact
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
