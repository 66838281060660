import "../Custom.css";
import {
  ACCOUNTING_SERVICE_PAGE_IMAGE_FOUR,
  ACCOUNTING_SERVICE_PAGE_IMAGE_ONE,
  ACCOUNTING_SERVICE_PAGE_IMAGE_THREE,
  ACCOUNTING_SERVICE_PAGE_IMAGE_TWO,
} from "../utils/constants";

const Accounting = () => {
  return (
    <div className="container pb-5">
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="row p-2 animated animatedFadeInUp fadeInUp animation-delay3">
            <h1
              className="fw-bold d-none d-md-block text-secondary text-black"
              style={{ fontSize: "80px" }}
            >
              Check what we do<span className="text-warning">.</span>
            </h1>
            <h1
              className="fw-bold d-block d-md-none text-secondary text-black"
              style={{ fontSize: "40px" }}
            >
              Check what we do<span className="text-warning">.</span>
            </h1>
          </div>

          <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay3">
            <img
              src={ACCOUNTING_SERVICE_PAGE_IMAGE_ONE}
              className="transform"
              alt="tax registration"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay4">
            <p className="fw-bold text-secondary">Tax Registration</p>
            <p style={{ color: "#495057" }}>
              <small>
                Comprehensive tax registration services tailored for the UAE.
                Seamlessly navigate VAT regulations with our expert guidance.
                From initial registration to compliance, ensure your business
                meets all UAE taxation requirements effortlessly.
              </small>
            </p>
          </div>

          <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay3">
            <img
              src={ACCOUNTING_SERVICE_PAGE_IMAGE_TWO}
              className="transform"
              alt="return filing"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay4">
            <p className="fw-bold text-secondary">Return Filing</p>
            <p style={{ color: "#495057" }}>
              <small>
                Effortlessly manage your tax obligations with our comprehensive
                return filing service. We ensure accurate, timely submissions,
                minimizing hassle and maximizing your peace of mind. Trust us to
                handle your returns efficiently.
              </small>
            </p>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay5">
            <img
              src={ACCOUNTING_SERVICE_PAGE_IMAGE_THREE}
              className="transform"
              alt="consultancy"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay6">
            <p className="fw-bold text-secondary">Consultancy</p>
            <p style={{ color: "#495057" }}>
              <small>
                Empower your financial future with our consultancy services.
                From investment guidance to financial planning, we offer expert
                advice tailored to your goals. Let us navigate your path to
                financial success together.
              </small>
            </p>
          </div>

          <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay5">
            <img
              src={ACCOUNTING_SERVICE_PAGE_IMAGE_FOUR}
              className="transform"
              alt="accounting"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay6">
            <p className="fw-bold text-secondary">Accounting</p>
            <p style={{ color: "#495057" }}>
              <small>
                Navigate financial complexities effortlessly with our
                comprehensive accounting services. From meticulous bookkeeping
                to strategic financial analysis, trust us to handle your numbers
                while you focus on what matters most – your business growth.
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accounting;
