import "../Custom.css";
import {
  CONSULTANTS_SERVICE_PAGE_IMAGE_FOUR,
  CONSULTANTS_SERVICE_PAGE_IMAGE_ONE,
  CONSULTANTS_SERVICE_PAGE_IMAGE_THREE,
  CONSULTANTS_SERVICE_PAGE_IMAGE_TWO,
} from "../utils/constants";

const BusinessConsultant = () => {
  return (
    <div className="container pb-5">
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="row p-2 animated animatedFadeInUp fadeInUp animation-delay3">
            <h1
              className="fw-bold d-none d-md-block text-secondary text-black"
              style={{ fontSize: "80px" }}
            >
              Check what we do<span className="text-warning">.</span>
            </h1>
            <h1
              className="fw-bold d-block d-md-none text-secondary text-black"
              style={{ fontSize: "40px" }}
            >
              Check what we do<span className="text-warning">.</span>
            </h1>
          </div>

          <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay3">
            <img
              src={CONSULTANTS_SERVICE_PAGE_IMAGE_ONE}
              className="transform"
              alt="entry strategy"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay4">
            <p className="fw-bold text-secondary">Entry Strategy</p>
            <p style={{ color: "#495057" }}>
              <small>
                Explore new markets with confidence. We conduct in-depth market
                research and craft bespoke entry strategies for your successful
                expansion into international markets.
              </small>
            </p>
          </div>

          <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay3">
            <img
              src={CONSULTANTS_SERVICE_PAGE_IMAGE_TWO}
              className="transform"
              alt="compliance assistance"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay4">
            <p className="fw-bold text-secondary">Compliance Assistance</p>
            <p style={{ color: "#495057" }}>
              <small>
                Navigate foreign regulations effortlessly. Our expert guidance
                ensures your business complies with legal, financial, and
                operational requirements in overseas territories.
              </small>
            </p>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay5">
            <img
              src={CONSULTANTS_SERVICE_PAGE_IMAGE_THREE}
              className="transform"
              alt="cultural adaptation"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay6">
            <p className="fw-bold text-secondary">Cultural Adaptation</p>
            <p style={{ color: "#495057" }}>
              <small>
                Connect with local audiences seamlessly. We help you understand
                and adapt to diverse cultures, ensuring your products/services
                resonate effectively.
              </small>
            </p>
          </div>

          <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay5">
            <img
              src={CONSULTANTS_SERVICE_PAGE_IMAGE_FOUR}
              className="transform"
              alt="ongoing Support"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay6">
            <p className="fw-bold text-secondary">Ongoing Support</p>
            <p style={{ color: "#495057" }}>
              <small>
                Stay supported throughout your international journey. We provide
                continuous guidance and management support as you establish and
                grow your presence in global markets.
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessConsultant;
