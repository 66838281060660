import "./App.css";
import Header from "./components/Header";
import Main from "./components/Main";
import Services from "./components/Services";
import IntCommerce from "./components/IntCommerce";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import DigitalSolutions from "./components/DigitalSolutions";
import BusinessConsultant from "./components/BusinessConsultant";
import Accounting from "./components/Accounting";

import { Routes, Route } from "react-router-dom";


function App() {
  return (
    // <BrowserRouter> //Declared in index.js
    <Routes>
      <Route
        exact
        path="/"
        element={
          <>
            <Header />
            <Main />
            <Services />
            <Footer />
          </>
        }
      />
      <Route
        path="/about"
        element={
          <>
            <Header />
            <About />
            <Footer />
          </>
        }
      />
      <Route
        path="/international-commerce"
        element={
          <>
            <Header />
            <IntCommerce />
            <Footer />
          </>
        }
      />
      <Route
        path="/accounting-and-bookkeeping"
        element={
          <>
            <Header />
            <Accounting />
            <Footer />
          </>
        }
      />
      <Route
        path="/overseas-business-consultants"
        element={
          <>
            <Header />
            <BusinessConsultant />
            <Footer />
          </>
        }
      />
      <Route
        path="/digital-solutions"
        element={
          <>
            <Header />
            <DigitalSolutions />
            <Footer />
          </>
        }
      />
      <Route
        path="/contact"
        element={
          <>
            <Header />
            <Contact />
            <Footer />
          </>
        }
      />
    </Routes>
    // </BrowserRouter>
  );
}

export default App;
