import { useEffect, useState } from "react";
import "../Custom.css";
import {
  HOMEPAGE_MAIN_BANNER,
  HOMEPAGE_SECOND_BANNER,
} from "../utils/constants";

const Main = () => {
  const bannerText =
    "Achieving growth through integrated business solutions...";
  // We will keep track of how many letters are being displayed
  const [index, setIndex] = useState(0);

  const type = () => {
    setIndex((prev) => Math.min(prev + 1, bannerText.length));
  };

  useEffect(() => {
    const id = setInterval(type, 50);

    // It is important to clear the interval when the component unmounts!
    return () => clearInterval(id);
  }, []); // We are setting an empty dep array

  return (
    <main className="overflow-hidden">
      <div className="row position-relative pb-3">
        <img
          src={HOMEPAGE_MAIN_BANNER}
          className="img-fluid animated animatedFadeInUp fadeInUp animation-delay2 d-none d-md-block vh-100"
          alt="banner"
        />
        <img
          src={HOMEPAGE_MAIN_BANNER}
          className="img-fluid animated animatedFadeInUp fadeInUp animation-delay2 d-block d-md-none"
          alt="banner"
        />
        <div className="position-absolute top-50 start-0 translate-middle-y p-5">
          {/* View Card on Tablet device and above */}
          <div className="card rounded-0 w-25 d-none d-md-block bg-black animated animatedFadeInLeft fadeInLeft animation-delay3">
            <div className="card-body p-4 justify-content-center text-white fw-bold">
              <h2>{bannerText.substring(0, index)}</h2>
            </div>
          </div>
          {/* View Card on Smaller device and above */}
          <div className="card rounded-0 w-50 d-block d-md-none bg-black animated animatedFadeInLeft fadeInLeft animation-delay3">
            <div className="card-body p-3 justify-content-center text-white fw-bold">
              <h6>{bannerText.substring(0, index)}</h6>
            </div>
          </div>
        </div>
      </div>

      <div className="row m-3 py-3">
        <div className="col-12 pb-5 col-md-6 pb-md-0 overflow-hidden text-center">
          <img
            src={HOMEPAGE_SECOND_BANNER}
            height={"100%"}
            width={"100%"}
            alt="about-section"
          />
        </div>
        <div
          className="col-12 col-md-6 d-flex align-items-center text-center"
          style={{ backgroundColor: "#000", color: "lightgray" }}
        >
          <div className="col-12">
            <div className="row mx-3">
              <p className="px-2 py-4 p-lg-3 animated animatedFadeInRight fadeInRight animation-delay4">
                The <span className="text-warning">Morifar Group</span> is a
                diversified group of companies that focuses on long-standing
                business relationships. With an extensive investment portfolio,
                we work with like-minded entrepreneurs who coherently drive a
                strong synergy to the group’s core business ethics.
              </p>
            </div>
            <hr className="text-warning" />
            <div className="row m-3">
              <p
                className="px-4 pt-4 animated animatedFadeInUp fadeInUp animation-delay6"
                style={{ fontSize: "12.5px" }}
              >
                <i>
                  <span style={{ color: "lightgrey" }}>
                    “ The future belong to those who can imagine it, design it
                    and execute it. ”
                  </span>
                </i>
                <br />
                <br />
                <span style={{ color: "grey" }}>
                  - HH Sheikh Mohammed Bin Rashid Al Maktoum
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center border border-warning bg-transparent">
        <div className="row py-lg-3">
          <div className="col-md-6 p-lg-5 mx-auto my-5">
            <p className="border border-2 border-warning p-5 shadow-lg bg-light animated animatedFadeInUp fadeInUp animation-delay5">
              Operating as Service Agency, a production resource and brand
              partner, Morifar deals with business to bring with compelling
              content, experience and partnerships to life
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};
export default Main;
