import "../Custom.css";
import {
  COMPANY_CONTACT_ADDRESS,
  COMPANY_CONTACT_EMAIL,
  COMPANY_CONTACT_PHONE,
  COMPANY_GOOGLE_MAPS,
  COMPANY_TIMINGS,
  COMPANY_WORKING_DAYS,
} from "../utils/constants";

const Contact = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 d-md-flex d-none bg-dark text-white d-flex justify-content-center align-items-center animated animatedFadeInUp fadeInUp animation-delay3">
          <h1 style={{ fontSize: "80px", transform: "rotate(-90deg)" }}>
            Contact<span className="text-warning">.</span>
          </h1>
        </div>
        <div className="col-12 d-md-none d-block bg-dark text-white text-center">
          <h1
            className="animated animatedFadeInUp fadeInUp animation-delay3"
            style={{ fontSize: "40px" }}
          >
            Contact<span className="text-warning">.</span>
          </h1>
        </div>

        <div className="col-12 col-md-4 text-center border-right animated animatedFadeInUp fadeInUp animation-delay4">
          <div className="row">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-geo"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"
              />
            </svg>
            <br />
            <br />
            <h5> {COMPANY_CONTACT_ADDRESS} </h5>
          </div>

          <br />
          <br />

          <div className="row">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-clock"
              viewBox="0 0 16 16"
            >
              <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
            </svg>
            <br />
            <br />
            <h5>{COMPANY_TIMINGS}</h5>
            <h5>{COMPANY_WORKING_DAYS}</h5>
          </div>

          <br />
          <br />

          <div className="row">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-telephone"
              viewBox="0 0 16 16"
            >
              <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
            </svg>
            <br />
            <br />
            <h5>{COMPANY_CONTACT_PHONE}</h5>
            <h5>{COMPANY_CONTACT_EMAIL}</h5>
          </div>
        </div>

        <div className="col-12 col-md-4 d-flex justify-content-center align-items-center animated animatedFadeInUp fadeInUp animation-delay6">
          <iframe
            src={COMPANY_GOOGLE_MAPS}
            width="400"
            height="500"
            style={{ border: "0" }}
            allowfullscreen=""
            title="maps"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
