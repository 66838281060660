import { useState, useEffect } from "react";
import logo from "../logo.jpg";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const [showMenu, setShowMenu] = useState("");
  const [headerTitle, setHeaderTitle] = useState("");

  const lastSegment = useLocation();

  useEffect(() => {
    if (lastSegment.pathname.includes("/international-commerce"))
      setHeaderTitle("International Commerce");
    else if (lastSegment.pathname.includes("/accounting-and-bookkeeping"))
      setHeaderTitle("Accounting & Bookkeeping");
    else if (lastSegment.pathname.includes("/overseas-business-consultants"))
      setHeaderTitle("Overseas Business Consultants");
    else if (lastSegment.pathname.includes("digital-solutions"))
      setHeaderTitle("Digital Solutions");
    else setHeaderTitle("Morifar Group");
  }, [lastSegment]);

  const handleMenuToggle = () => {
    showMenu === "show" ? setShowMenu("") : setShowMenu("show");
  };

  return (
    // <BrowserRouter>
    <header style={{ marginBottom: "65px" }}>
      <nav
        className="navbar navbar-dark fixed-top bg-black"
        aria-label="First navbar example"
        style={{ opacity: "0.9" }}
      >
        <div className="container-fluid">
          <Link
            to="/"
            onClick={() => setShowMenu("")}
            style={{ textDecoration: "none" }}
          >
            <span className="navbar-brand">
              <img src={logo} width="50" height="auto" alt="logo" />
            </span>
          </Link>
          <span className="text-warning">
            <small> {headerTitle} </small>
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
            role="button"
            onClick={handleMenuToggle}
            style={{ color: "#d3b347" }}
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>

          <div
            className={"collapse navbar-collapse " + showMenu}
            id="navbarsExample01"
          >
            <ul className="navbar-nav me-auto mb-2 mt-3 text-center">
              <li
                className="nav-item pb-4 pt-2 pt-lg-1"
                onClick={handleMenuToggle}
              >
                <Link to="/about" style={{ textDecoration: "none" }}>
                  <span className="nav-link animated animatedFadeInUp fadeInUp animation-delay">
                    <h1>Who we are</h1>
                  </span>
                </Link>
              </li>
              <li className="nav-item pb-5" onClick={handleMenuToggle}>
                <Link
                  to="/international-commerce"
                  style={{ textDecoration: "none" }}
                >
                  <span className="nav-link animated animatedFadeInUp fadeInUp animation-delay2">
                    <h1>International Commerce</h1>
                  </span>
                </Link>
              </li>
              <li className="nav-item pb-5" onClick={handleMenuToggle}>
                <Link
                  to="/accounting-and-bookkeeping"
                  style={{ textDecoration: "none" }}
                >
                  <span className="nav-link animated animatedFadeInUp fadeInUp animation-delay4">
                    <h1>Accounting & Bookkeeping</h1>
                  </span>
                </Link>
              </li>
              <li className="nav-item pb-5" onClick={handleMenuToggle}>
                <Link
                  to="/overseas-business-consultants"
                  style={{ textDecoration: "none" }}
                >
                  <span className="nav-link animated animatedFadeInUp fadeInUp animation-delay5">
                    <h1>Overseas Business consultants</h1>
                  </span>
                </Link>
              </li>
              <li className="nav-item pb-5" onClick={handleMenuToggle}>
                <Link
                  to="/digital-solutions"
                  style={{ textDecoration: "none" }}
                >
                  <span className="nav-link animated animatedFadeInUp fadeInUp animation-delay5">
                    <h1>Digital Solutions</h1>
                  </span>
                </Link>
              </li>
              <li className="nav-item pb-5" onClick={handleMenuToggle}>
                <Link to="/contact" style={{ textDecoration: "none" }}>
                  <span className="nav-link animated animatedFadeInUp fadeInUp animation-delay6">
                    <h1>Contact</h1>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>

    // </BrowserRouter>
  );
};

export default Header;
