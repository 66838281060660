import "../Custom.css";
import { Link } from "react-router-dom";
import {
  HOMEPAGE_DIGITAL_SERVICE_BANNER,
  HOMEPAGE_INT_COMMERCE_BANNER,
  HOMEPAGE_CONSULTANTS_SERVICE_BANNER,
  HOMEPAGE_ACCOUNTING_SERVICE_BANNER,
} from "../utils/constants";

const Services = () => {
  return (
    <>
      <div className="bg"></div>
      <div className="bg bg2"></div>
      <div className="bg bg3"></div>

      <div className="container pb-5">
        <div className="row gx-5">
          <div className="col-12 col-md-6">
            <div className="row p-2 animated animatedFadeInUp fadeInUp animation-delay3">
              <h1
                className="fw-bold d-none d-md-block text-secondary"
                style={{ fontSize: "80px" }}
              >
                Our Services<span className="text-warning">.</span>
              </h1>
              <h1
                className="fw-bold d-block d-md-none text-secondary"
                style={{ fontSize: "40px" }}
              >
                Our Services<span className="text-warning">.</span>
              </h1>
            </div>

            <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay3">
              <img
                src={HOMEPAGE_INT_COMMERCE_BANNER}
                className="transform"
                alt="general-trading"
                height="auto"
                width="auto"
              />
            </div>

            <Link
              to="/international-commerce"
              style={{ textDecoration: "none" }}
            >
              <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay4 shadow-lg">
                <p className="fw-bold text-black">International Commerce</p>
                <p style={{ color: "#495057" }}>
                  <small>
                    <span className="fw-bold">
                      Morifar General Trading LLC (UAE) & Morifar Exim Pvt Ltd
                      (India)
                    </span>{" "}
                    is the trading arm of the group. The company sources and
                    trades precious metals, Agarwood (Oud Oil & Natural Oud
                    Sticks), Copper, steel (fresh & scrap), Aluminium, Gold,
                    Silver, Clothing & Accessories.
                  </small>
                </p>
              </div>
            </Link>

            <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay3">
              <img
                src={HOMEPAGE_DIGITAL_SERVICE_BANNER}
                className="transform"
                alt="digital-solutions"
                height="auto"
                width="auto"
              />
            </div>

            <Link to="/digital-solutions" style={{ textDecoration: "none" }}>
              <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay4 shadow-lg">
                <p className="fw-bold text-black">Digital Solutions</p>
                <p style={{ color: "#495057" }}>
                  <small>
                    <span className="fw-bold">
                      Morifar International Limited (UK)
                    </span>{" "}
                    provides creative digital solutions for business and
                    branding, website creation, content management, application
                    development and online marketing.
                  </small>
                </p>
              </div>
            </Link>
          </div>

          <div className="col-12 col-md-6">
            <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay5">
              <img
                src={HOMEPAGE_CONSULTANTS_SERVICE_BANNER}
                className="transform"
                alt="overseas-business-consultants"
                height="auto"
                width="auto"
              />
            </div>

            <Link
              to="/overseas-business-consultants"
              style={{ textDecoration: "none" }}
            >
              <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay6 shadow-lg">
                <p className="fw-bold text-black">
                  Overseas Business Consultants
                </p>
                <p style={{ color: "#495057" }}>
                  <small>
                    <span className="fw-bold">
                      Advani Overseas Consultants (UK)
                    </span>{" "}
                    helps businesses with personalized advice, smart strategies,
                    and practical solutions. We empower clients to tackle
                    challenges, meet goals, and grow in today's competitive
                    business world.
                  </small>
                </p>
              </div>
            </Link>

            <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay5">
              <img
                src={HOMEPAGE_ACCOUNTING_SERVICE_BANNER}
                className="transform"
                alt="accounting-and-bookkeeping"
                height="auto"
                width="auto"
              />
            </div>

            <Link
              to="/accounting-and-bookkeeping"
              style={{ textDecoration: "none" }}
            >
              <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay6 shadow-lg">
                <p className="fw-bold text-black">Accounting & Bookkeeping</p>
                <p style={{ color: "#495057" }}>
                  <small>
                    <span className="fw-bold">
                      Al Asr International LLC (UAE)
                    </span>{" "}
                    provides e-governance and PRO services, VAT related services
                    such as tax registration, return-filing, consultancy and
                    accounting.
                  </small>
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
