import "../Custom.css";
import { COMPANY_CEO_AVATAR } from "../utils/constants";

const About = () => {
  return (
    <div className="container-fluid">
      <div className="row bg-white">
        <div className="col-12 col-md-4 d-none d-md-flex justify-content-center">
          <h1
            className="position-fixed"
            style={{ color: "#878787", fontSize: "80px" }}
          >
            About<span className="text-warning">.</span>
          </h1>
        </div>
        <div className="col-12 col-md-4 d-md-none d-flex justify-content-center">
          <h1 style={{ color: "#878787", fontSize: "80px" }}>
            About<span className="text-warning">.</span>
          </h1>
        </div>

        <div className="col-12 col-md-8">
          <div className="row px-5 py-5 bg-dark" style={{ color: "#878787" }}>
            <p className="animated animatedFadeInUp fadeInUp animation-delay3">
              <small>
                The <span className="text-warning">Morifar Group</span> is a
                conglomerate of various businesses that cater to industries such
                as finance, international commodity trade, legal services,
                wealth management, construction, audit and tax registration,
                digital solutions, and hospitality. The group was founded in
                early 2018 and does business with global brands such as
                Thyssenkrupp, Skyline Creative Gold and Jewellery, and Ferreum
                NV. Our business model is straight-forward and effective. Our
                team includes professionals experienced in recognising
                commercially lucrative opportunities and converting them into
                growing business.
              </small>
            </p>
            <br />
            <p className="animated animatedFadeInUp fadeInUp animation-delay3">
              <small>
                <span className="fw-bold">Mission</span>
                <br />
                We are the agents of possibilities. We love ideas. Our mission
                is to drive growth and be recognized as the brand with a
                diversified business.
              </small>
            </p>
            <br />
            <p className="animated animatedFadeInUp fadeInUp animation-delay3">
              <small>
                <span className="fw-bold">Vision</span>
                <br />
                Our vision is to empower businesses and people. Our focus on
                unique opportunities will help us achieve sustainable growth. We
                will do this by building the right partnerships with the right
                brands, through uncompromised quality and highest standards of
                service.
              </small>
            </p>
          </div>

          <div className="row px-5 p-2 py-5 bg-black">
            {/* <div className="col-2 d-flex align-items-center animated animatedFadeInUp fadeInUp animation-delay5">
                            <img src={COMPANY_CEO_AVATAR} alt="Avatar" style={{width: '100px', borderRadius: '50%'}} />
                        </div> */}
            <div className="col-10 d-flex align-items-center justify-content-start animated animatedFadeInUp fadeInUp animation-delay6">
              <h6 className="text-warning">
                Mohammed Rizwan Advani <br />
                <span style={{ color: "#fff" }}>C E O</span>
              </h6>
            </div>
            <br />
            <div className="row pt-5 animated animatedFadeInUp fadeInUp animation-delay3">
              <p className="text-secondary">
                Rizwan is a successful leader with a unique ability to foresee
                business opportunities in key emerging markets. Being into
                business since 2002, he was driven by a single-minded goal of
                becoming a progressive entrepreneur and started the Morifar
                Group in early 2018. Rizwan follows a disciplined investment
                strategy which generates stable returns over a long period with
                low risk rates. With an in-depth knowledge of business and
                finance he has become a successful investor in diversified
                businesses. His strong strategic foresight, passion for
                innovation, unrivalled corporate values signify him as a
                catalyst for change. His outstanding leadership is the guiding
                principle for the Morifar Group.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
